/* eslint-disable angular/timeout-service */
import { isString } from './type-utils';

const doc = window.document;

export function docReady(fn) {
  // see if DOM is already available
  if (doc.readyState === 'complete' || doc.readyState === 'interactive') {
    // call on next available tick
    window.setTimeout(fn, 1);
  } else {
    doc.addEventListener('DOMContentLoaded', fn);
  }
}

/**
 * Creates a before unload event handler.
 *
 * @param {function(Event): string?} handler - A function that returns a message to warn when the window should not be
 *   closed. Return a falsy value or nothing if there's no need to warn.
 * @returns {(function(): void)} Deregistration function, call to stop listening to the event.
 */
export function warnBeforeClosing(handler) {
  const _beforeUnload = (event) => {
    const result = handler(event);
    if (result) {
      event.preventDefault();
      event.returnValue = result;
      return result;
    }
  };
  window.addEventListener('beforeunload', _beforeUnload);
  return () => {
    window.removeEventListener('beforeunload', _beforeUnload);
  };
}

export function fixQuery(s) {
  // corrects IDs that aren't valid CSS-style IDs
  return s.replace(/#([^a-z][-\w]+)/gi, '[id="$1"]');
}

export function querySelector(s) {
  return isString(s) ? doc.querySelector(fixQuery(s)) : s;
}

export function querySelectorAll(s) {
  return isString(s) ? doc.querySelectorAll(fixQuery(s)) : s;
}

export function focusElement(
  focusedEl = document.activeElement,
  backwards = false,
) {
  const focussableElements =
    'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
  if (focusedEl) {
    const focusable = Array.prototype.filter.call(
      document.querySelectorAll(focussableElements),
      basicIsVisible,
    );
    const index = focusable.indexOf(focusedEl);
    focusable[index + (backwards ? -1 : 1)].focus();
  }
}

export function focusNextElement(focusedEl) {
  return focusElement(focusedEl);
}

export function focusPreviousElement(focusedEl) {
  return focusElement(focusedEl, true);
}

export function anchorFor(url) {
  let anchor = document.createElement('a');
  anchor.href = url;
  return anchor;
}

export function hostname(url) {
  return anchorFor(url).hostname;
}

export function equivalentUrl(urlA, urlB) {
  return hostname(urlA) === hostname(urlB);
}

// Returns true if neither this node nor any of it's parents are not explicitly hidden via display/visibility ONLY
// Ignores opacity, etc.
export function basicIsVisible(el) {
  const p = el.parentNode;

  if (!p) {
    return false;
  }

  //-- Return true for document node
  if (9 === p.nodeType) {
    return true;
  }

  //-- Return false if our element is invisible
  if (
    'none' === getStyle(el, 'display') ||
    'hidden' === getStyle(el, 'visibility')
  ) {
    return false;
  }

  // recurse upwards
  return basicIsVisible(p);
}

////// true-visibility function: checks to see if a node is on-screen or not

/**
 * Author: Jason Farrell
 * Author URI: http://useallfive.com/
 *
 * Description: Checks if a DOM element is truly visible.
 * Package URL: https://github.com/UseAllFive/true-visibility
 */

/**
 * Checks if a DOM element is visible. Takes into
 * consideration its parents and overflow.
 *
 * @param (el)      the DOM element to check if is visible
 *
 * These params are optional that are sent in recursively,
 * you typically won't use these:
 *
 * @param recurse   Recurse upwards
 * @param (t)       Top corner position number
 * @param (r)       Right corner position number
 * @param (b)       Bottom corner position number
 * @param (l)       Left corner position number
 * @param (w)       Element width number
 * @param (h)       Element height number
 */
export function isVisible(el, recurse, t, r, b, l, w, h) {
  const p = el.parentNode;
  const VISIBLE_PADDING = 2;
  const offsetParent = el.offsetParent;

  if (!elementInDocument(el)) {
    return false;
  }

  //-- Return true for document node
  if (9 === p.nodeType) {
    return true;
  }

  if ('fixed' === getStyle(el, 'position')) {
    // assume fixed nodes are on-screen
    return true;
  }

  //-- Return false if our element is invisible
  if (
    '0' === getStyle(el, 'opacity') ||
    'none' === getStyle(el, 'display') ||
    'hidden' === getStyle(el, 'visibility')
  ) {
    return false;
  }

  //-- If we have a parent, let's continue:
  if (recurse && p) {
    if (
      t === undefined ||
      r === undefined ||
      b === undefined ||
      l === undefined ||
      w === undefined ||
      h === undefined
    ) {
      t = el.offsetTop;
      l = el.offsetLeft;
      b = t + el.offsetHeight;
      r = l + el.offsetWidth;
      w = el.offsetWidth;
      h = el.offsetHeight;
    }
    let overflow = getStyle(p, 'overflow');
    //-- Check if the parent can hide its children. Ignore inline elements, since it makes no sense
    if (
      getStyle(p, 'display') !== 'inline' &&
      ('hidden' === overflow || 'scroll' === overflow || 'auto' === overflow)
    ) {
      let offL = 0;
      let offT = 0;
      if (offsetParent !== p) {
        // this isn't always the offset parent, even though it can scroll
        offL = p.offsetLeft;
        offT = p.offsetTop;
      }
      // I'm leaving this debugging code, it's a bitch to type in
      // console.log('[parent check]', { t, l, b, r, w, h },
      // 	[l + VISIBLE_PADDING, offL + p.offsetWidth + p.scrollLeft, l + VISIBLE_PADDING > offL + p.offsetWidth + p.scrollLeft],
      // 	[l + w - VISIBLE_PADDING, p.scrollLeft + offL, l + w - VISIBLE_PADDING < p.scrollLeft + offL],
      // 	[t + VISIBLE_PADDING, offT + p.offsetHeight + p.scrollTop, t + VISIBLE_PADDING > p.offsetHeight + offT + p.scrollTop],
      // 	[t + h - VISIBLE_PADDING, offT + p.scrollTop, t + h - VISIBLE_PADDING < offT + p.scrollTop], p, el);

      //-- Only check if the offset is different for the parent
      if (
        //-- If the target element is to the right of the parent elm
        l + VISIBLE_PADDING > offL + p.offsetWidth + p.scrollLeft ||
        //-- If the target element is to the left of the parent elm
        l + w - VISIBLE_PADDING < offL + p.scrollLeft ||
        //-- If the target element is under the parent elm
        t + VISIBLE_PADDING > offT + p.offsetHeight + p.scrollTop ||
        //-- If the target element is above the parent elm
        t + h - VISIBLE_PADDING < offT + p.scrollTop
      ) {
        //-- Our target element is out of bounds:
        return false;
      }
    }

    //-- Add the offset parent's left/top coords to our element's offset:
    if (offsetParent === p) {
      l += p.offsetLeft - p.scrollLeft;
      t += p.offsetTop - p.scrollTop;
    } else if (offsetParent !== p.offsetParent) {
      // captures weird case of inline-block inside a table cell, which causes a different offsetParent for some reason.
      l += offsetParent.offsetLeft - offsetParent.scrollLeft;
      t += offsetParent.offsetTop - offsetParent.scrollTop;
      return isVisible(offsetParent, true, t, r, b, l, w, h);
    }
    //-- Let's recursively check upwards:
    return isVisible(p, true, t, r, b, l, w, h);
  }

  // default: it's visible
  return true;
}

//-- Cross browser method to get style properties:
export function getStyle(el, property) {
  if (el.nodeType === 9) return null;
  if (window.getComputedStyle) {
    return document.defaultView
      .getComputedStyle(el, null)
      .getPropertyValue(property);
  }
  if (el.currentStyle) {
    return el.currentStyle[property];
  }
}

export function elementInDocument(element) {
  while ((element = element.parentNode)) {
    if (element === document) {
      return true;
    }
  }
  return false;
}
