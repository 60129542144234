/* eslint-plugin-disable angular */
import { basicIsVisible, getStyle, isVisible } from '../utils/dom-utils';

((angular, window, document) => {
  const jqFn = angular.element.prototype;
  const ArrayPrototype = Array.prototype;

  // add new functions to JQLite
  jqFn.every = function (fn) {
    return ArrayPrototype.every.call(this, fn);
  };
  jqFn.some = function (fn) {
    return ArrayPrototype.some.call(this, fn);
  };
  jqFn.map = function (fnName, ...args) {
    return ArrayPrototype.map.call(this, (el) => {
      const $el = angular.element(el);
      return $el[fnName](...args);
    });
  };

  jqFn.querySelector = function (q) {
    const el = this[0];
    return angular.element(el ? el.querySelector(q) : null);
  };
  jqFn.querySelectorAll = function (q) {
    const el = this[0];
    return angular.element(el ? el.querySelectorAll(q) : null);
  };

  function _performAction(action) {
    return function () {
      const el = this[0];
      el && el[action] && el[action]();
      return this;
    };
  }

  jqFn.focus = _performAction('focus');
  // selects the input OR a range within the input
  jqFn.selectInput = function (start, end) {
    const input = this[0];
    if (input && input.select && input.focus) {
      input.focus();
      if (start === undefined) {
        input.select(); // select all
      } else {
        if (end === undefined) end = this.val().length;
        if (input.selectionStart !== undefined) {
          input.selectionStart = start;
          input.selectionEnd = end;
        } else {
          input.select();
        }
      }
    }
    return this;
  };

  jqFn.isVisible = function (recurse = true) {
    return (
      this.length > 0 &&
      this.every((el) =>
        recurse === 'basic' ? basicIsVisible(el) : isVisible(el, recurse),
      )
    );
  };

  jqFn.isInView = function (padding = 10) {
    const el = this[0];
    if (!el) return false;
    let rect = el.getBoundingClientRect();
    const $win = angular.element(window);
    return (
      rect.top >= padding + this.scrollableParent().getFixedNodeOffsets() &&
      rect.left >= padding &&
      rect.bottom <= $win.offsetHeight() - padding &&
      rect.right <= $win.offsetWidth() - padding
    );
  };

  // calculates offsets due to known fixed nodes within the scroll target
  jqFn.getFixedNodeOffsets = function () {
    let offset = 0;
    if (this.length) {
      let scrollTarget = this[0];
      if (scrollTarget === window) {
        scrollTarget = document;
      }
      if (scrollTarget && scrollTarget.querySelectorAll) {
        // track all fixed top, but only the first sticky-header (since they stack)
        let foundSticky = false;
        angular.forEach(
          scrollTarget.querySelectorAll('.sticky-header, .navbar-fixed-top'),
          (h) => {
            if (h.classList.contains('sticky-header')) {
              offset += foundSticky ? 0 : h.offsetHeight;
              foundSticky = true;
            } else {
              offset += h.offsetHeight;
            }
          },
        );
      }
    }
    return offset;
  };

  jqFn.getStyle = function (property) {
    if (!this[0]) return null;
    return getStyle(this[0], property);
  };

  jqFn.isHidden = function () {
    return this.getStyle('display') === 'none';
  };

  jqFn.offsetTop = function () {
    let e = this[0];
    let s = 0;
    if (e !== window) {
      while (e && e.nodeType !== 9) {
        s += e.offsetTop;
        e = e.offsetParent;
      }
    }
    return s;
  };

  jqFn.offsetLeft = function () {
    let e = this[0];
    let s = 0;
    if (e !== window) {
      while (e && e.nodeType !== 9) {
        s += e.offsetLeft;
        e = e.offsetParent;
      }
    }
    return s;
  };

  jqFn.offsetHeight = function () {
    let e = this[0];
    if (e === window) {
      return 'innerHeight' in e
        ? e.innerHeight
        : document.documentElement.offsetHeight;
    } else {
      return e ? e.offsetHeight : 0;
    }
  };

  jqFn.offsetWidth = function () {
    let e = this[0];
    if (e === window) {
      return 'innerWidth' in e
        ? e.innerWidth
        : document.documentElement.offsetWidth;
    } else {
      return e ? e.offsetWidth : 0;
    }
  };

  jqFn.offset = function () {
    const ret = {
      top: this.offsetTop(),
      left: this.offsetLeft(),
      width: this.offsetWidth(),
      height: this.offsetHeight(),
    };
    ret.right = ret.left + ret.width;
    ret.bottom = ret.top + ret.height;
    return ret;
  };

  jqFn.scrollTop = function (val) {
    let e = this[0];
    const getVal = val === undefined;
    if (!e) return 0;
    if (e === window) {
      if (window.scrollY === undefined) {
        if (getVal) {
          val = document.documentElement.scrollTop;
        } else {
          document.documentElement.scrollTop = val;
        }
      } else {
        if (getVal) {
          val = window.scrollY;
        } else {
          window.scrollTo(window.scrollX, val);
        }
      }
    } else {
      if (getVal) {
        val = e.scrollTop;
      } else {
        e.scrollTop = val;
      }
    }
    return val;
  };

  jqFn.isScrollable = function () {
    if (this[0] === window) return true;
    let overflow = this.getStyle('overflow');
    let overflowY = this.getStyle('overflow-y');
    return (
      overflow === 'auto' ||
      overflow === 'scroll' ||
      overflowY === 'auto' ||
      overflowY === 'scroll'
    );
  };

  jqFn.scrollableParent = function () {
    let node = this.parent();
    while (node.length && !node.isScrollable()) {
      node = node.parent();
    }
    if (!node.length) node = angular.element(window);
    return node;
  };
})(angular, window, document);
