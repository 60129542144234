/* eslint-disable no-console, angular/log, angular/no-private-call */
(function () {
  if (!window) return;
  let angular = window.angular;

  let $injector;
  const Dev = {
    find(service) {
      if (!$injector) {
        ['[ng-app]', 'body', '[ui-view]'].forEach(function (sel) {
          $injector =
            $injector ||
            angular.element(document.querySelector(sel)).injector();
        });
      }
      return $injector.get(service);
    },
    $apply(fn) {
      return Dev.$rootScope.$apply(fn);
    },
    changeUrl(url) {
      Dev.$apply(() => Dev.$location.url(url));
    },
    go() {
      Dev.$apply(() => Dev.$state.go.apply(Dev.$state, arguments));
    },
    $httpGet() {
      return Dev.$http.get.apply(null, arguments).then(
        (r) => console.log(r.data),
        (r) => console.error(r.data),
      );
    },
    $httpPost() {
      return Dev.$http.post.apply(null, arguments).then(
        (r) => console.log(r.data),
        (r) => console.error(r.data),
      );
    },
    scopeFor(el) {
      return angular.element(el).scope();
    },
    directiveCtrl(el, name) {
      return angular.element(el).data('$' + name + 'Controller');
    },
    watchers(root = '[ng-app]') {
      const watchers = [];

      if (angular.isString(root)) root = document.querySelector(root);
      root = angular.element(root);

      (function _findWatchers(element) {
        angular.forEach(['$scope', '$isolateScope'], function (scopeProperty) {
          if (element.data() && element.data().hasOwnProperty(scopeProperty)) {
            angular.forEach(
              element.data()[scopeProperty].$$watchers,
              function (watcher) {
                if (watchers.indexOf(watcher) < 0) {
                  watchers.push(watcher);
                }
              },
            );
          }
        });

        angular.forEach(element.children(), function (childElement) {
          _findWatchers(angular.element(childElement));
        });
      })(root);

      return watchers.length;
    },
    digestRate() {
      let msg;
      if (!Dev._dgs) {
        Dev._dgs = Date.now();
        Dev._dgc = 0;
        const stop = Dev.$rootScope.$watch(() => {
          Dev._dgc++;
        });
        Dev.digestRateStop = () => {
          Dev._dgs = 0;
          stop();
        };
        msg = 'Started watcher, stop with Dev.digestRateStop()';
      } else {
        const time = (Date.now() - Dev._dgs) / 1000;
        const count = Dev._dgc;
        msg = `digests/sec: ${Math.round((100 * count) / time) / 100} | sec: ${Math.round(time)} | digests: ${count} | watchers: ${Dev.watchers()}`;
      }
      return msg;
    },
  };
  const props = {};

  [
    '$rootScope',
    '$location',
    '$http',
    '$q',
    '$timeout',
    '$state',
    'DS',
    'allModels',
  ].forEach(function (name) {
    props[name] = {
      get() {
        return Dev.find(name);
      },
    };
  });
  props.models = props.allModels;
  Object.defineProperties(Dev, props);

  window.Dev = Dev;
})();
/* eslint-enable no-console, angular/log, angular/no-private-call */
