// Monkey-patch Angular.module to enable passing modules in directly as requires.
// This is a pretty dumb issue with the way Angular handles modules... Oh well.

let moduleFn = angular.module;

angular.module = function (moduleName, requires, configFn) {
  if (Array.isArray(requires)) {
    requires = requires.map((r) =>
      angular.isObject(r) && r.name ? r.name : r,
    );
  }
  return moduleFn(moduleName, requires, configFn);
};
